import React from "react"
import css from './Virtual.module.css'
import Shade from '../../assets/shade.png'
import ReactCompareImage from "react-compare-image"
import Before from '../../assets/before.png'
import After from '../../assets/after.png'
const Virtual=()=>{
    return(
        <div className={css.Virtual}>
            <div className={css.left}>
                <sapn>Virtual Try On</sapn>
                <sapn>Never Buy the worng Shade Again!</sapn>
                <sapn>Try Now</sapn>
                <img src={Shade} alt=""/>
            </div>

            <div className={css.righ}>
                <div className={css.wrapper}>
                    <ReactCompareImage leftImage={Before} rightImage={After} />
                </div>
            </div>

        </div>
    )
}
export default Virtual